// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Hooks from "../../../libs/Hooks.res.js";
import * as React from "react";
import * as Control from "../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Spinner from "../../../styleguide/components/Spinner/Spinner.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../styleguide/icons/IconSelect.res.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactDebounce from "rescript-debounce-react/src/ReactDebounce.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Api_LocationBrochure from "../../../api/location-brochures/Api_LocationBrochure.res.js";
import * as LocationBrochuresIndexHeader from "./components/header/LocationBrochuresIndexHeader.res.js";
import * as LocationBrochuresIndexScss from "./LocationBrochuresIndex.scss";
import * as LocationBrochuresIndexSidebar from "./components/sidebar/LocationBrochuresIndexSidebar.res.js";
import * as LocationBrochuresIndexBrochure from "./components/brochure/LocationBrochuresIndexBrochure.res.js";

var css = LocationBrochuresIndexScss;

function LocationBrochuresIndex$default(props) {
  var index = props.index;
  var initialState = React.useMemo((function () {
          return {
                  status: {
                    TAG: "Ready",
                    _0: index
                  },
                  currentPage: 1,
                  totalPages: 1,
                  sortBy: undefined,
                  search: "",
                  totalLocationBrochures: index.totalLocationBrochures,
                  currentTotalLocationBrochures: index.totalLocationBrochures,
                  providerIds: []
                };
        }), []);
  var container = React.useRef(null);
  var searchLocationBrochures = ReactDebounce.useDebounced(300, (function (param) {
          param.dispatch({
                TAG: "PerformSearch",
                _0: param.state.search
              });
        }));
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action !== "object") {
            switch (action) {
              case "FetchLocationBrochures" :
                  return {
                          TAG: "SideEffects",
                          _0: (function (param) {
                              var dispatch = param.dispatch;
                              var state = param.state;
                              $$Promise.wait(Api_LocationBrochure.index(state.currentPage, state.sortBy, state.search, state.providerIds), (function (x) {
                                      if (x.TAG === "Ok") {
                                        return dispatch({
                                                    TAG: "SucceedLocationBrochuresFetch",
                                                    _0: x._0
                                                  });
                                      }
                                      SentryLogger.error1({
                                            rootModule: "LocationBrochuresIndex",
                                            subModulePath: /* [] */0,
                                            value: "default",
                                            fullPath: "LocationBrochuresIndex.default"
                                          }, "LocationBrochuresIndex::FetchLocationBrochures::Error", [
                                            "Error",
                                            x._0
                                          ]);
                                      dispatch("FailLocationBrochuresFetch");
                                    }));
                            })
                        };
              case "FailLocationBrochuresFetch" :
                  return {
                          TAG: "Update",
                          _0: {
                            status: "FailedLocationBrochuresFetch",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            totalLocationBrochures: state.totalLocationBrochures,
                            currentTotalLocationBrochures: state.currentTotalLocationBrochures,
                            providerIds: state.providerIds
                          }
                        };
              case "Reset" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: "",
                            totalLocationBrochures: state.totalLocationBrochures,
                            currentTotalLocationBrochures: state.currentTotalLocationBrochures,
                            providerIds: []
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocationBrochures");
                            })
                        };
              
            }
          } else {
            switch (action.TAG) {
              case "SucceedLocationBrochuresFetch" :
                  var res = action._0;
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: {
                              TAG: "Ready",
                              _0: res
                            },
                            currentPage: res.currentPage,
                            totalPages: res.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            totalLocationBrochures: state.totalLocationBrochures,
                            currentTotalLocationBrochures: res.totalLocationBrochures,
                            providerIds: state.providerIds
                          },
                          _1: (function (param) {
                              Belt_Option.map(Caml_option.nullable_to_opt(container.current), (function (prim) {
                                      return prim.scrollTop;
                                    }));
                            })
                        };
              case "UpdatePage" :
                  var nextPage = action._0;
                  var match = state.status;
                  if (typeof match !== "object" && match === "FetchingLocationBrochures") {
                    return "NoUpdate";
                  }
                  if (state.currentPage !== nextPage) {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocationBrochures",
                              currentPage: nextPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              totalLocationBrochures: state.totalLocationBrochures,
                              currentTotalLocationBrochures: state.currentTotalLocationBrochures,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocationBrochures");
                              })
                          };
                  } else {
                    return "NoUpdate";
                  }
              case "SortBy" :
                  var match$1 = state.status;
                  if (typeof match$1 !== "object" && match$1 === "FetchingLocationBrochures") {
                    return "NoUpdate";
                  }
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: "FetchingLocationBrochures",
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: action._0,
                            search: state.search,
                            totalLocationBrochures: state.totalLocationBrochures,
                            currentTotalLocationBrochures: state.currentTotalLocationBrochures,
                            providerIds: state.providerIds
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocationBrochures");
                            })
                        };
              case "UpdateSearchInput" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: action._0,
                            totalLocationBrochures: state.totalLocationBrochures,
                            currentTotalLocationBrochures: state.currentTotalLocationBrochures,
                            providerIds: state.providerIds
                          },
                          _1: searchLocationBrochures
                        };
              case "PerformSearch" :
                  var match$2 = state.status;
                  if (typeof match$2 !== "object" && match$2 === "FetchingLocationBrochures") {
                    return "NoUpdate";
                  }
                  if (action._0 !== state.search) {
                    return "NoUpdate";
                  } else {
                    return {
                            TAG: "UpdateWithSideEffects",
                            _0: {
                              status: "FetchingLocationBrochures",
                              currentPage: state.currentPage,
                              totalPages: state.totalPages,
                              sortBy: state.sortBy,
                              search: state.search,
                              totalLocationBrochures: state.totalLocationBrochures,
                              currentTotalLocationBrochures: state.currentTotalLocationBrochures,
                              providerIds: state.providerIds
                            },
                            _1: (function (param) {
                                param.dispatch("FetchLocationBrochures");
                              })
                          };
                  }
              case "UpdateProviderIds" :
                  return {
                          TAG: "UpdateWithSideEffects",
                          _0: {
                            status: state.status,
                            currentPage: state.currentPage,
                            totalPages: state.totalPages,
                            sortBy: state.sortBy,
                            search: state.search,
                            totalLocationBrochures: state.totalLocationBrochures,
                            currentTotalLocationBrochures: state.currentTotalLocationBrochures,
                            providerIds: action._0
                          },
                          _1: (function (param) {
                              param.dispatch("FetchLocationBrochures");
                            })
                        };
              
            }
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var x = state.sortBy;
  var tmp;
  if (x !== undefined) {
    var variant = x.NAME;
    tmp = variant === "Title" ? (
        x.VAL === "Desc" ? "Sort by Title, Z to A" : "Sort by Title, A to Z"
      ) : (
        variant === "Location" ? (
            x.VAL === "Desc" ? "Sort by Location, Z to A" : "Sort by Location, A to Z"
          ) : (
            x.VAL === "Desc" ? "Sort by Provider, Z to A" : "Sort by Provider, A to Z"
          )
      );
  } else {
    tmp = "Choose Sort Criteria";
  }
  var res = state.status;
  var tmp$1;
  if (typeof res !== "object") {
    tmp$1 = res === "FetchingLocationBrochures" ? JsxRuntime.jsx("div", {
            children: JsxRuntime.jsx(Spinner.make, {
                  size: "XL",
                  color: "Teal"
                }),
            className: css.locationBrochuresList
          }) : JsxRuntime.jsx("div", {
            children: "Something went wrong. Please try again later."
          });
  } else {
    var res$1 = res._0;
    var match$1 = state.status;
    var tmp$2;
    if (typeof match$1 !== "object") {
      tmp$2 = null;
    } else if (res$1.totalPages < 2) {
      var match$2 = res$1.totalLocationBrochures;
      tmp$2 = match$2 !== 0 ? (
          match$2 !== 1 ? "Showing all" : "Showing 1"
        ) : (
          state.search === "" ? JsxRuntime.jsx("p", {
                  children: "No data center brochures were found."
                }) : JsxRuntime.jsx("p", {
                  children: "No data center brochures were found with that search query. Please try a different search."
                })
        );
    } else {
      tmp$2 = "Showing " + (String(res$1.offset + 1 | 0) + (" to " + (String(res$1.offset + res$1.locationBrochures.length | 0) + (" of " + String(res$1.totalLocationBrochures)))));
    }
    tmp$1 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: Belt_Array.mapWithIndex(res$1.locationBrochures, (function (index, show) {
                          return JsxRuntime.jsx(LocationBrochuresIndexBrochure.make, {
                                      show: show
                                    }, String(index));
                        })),
                  className: css.locationBrochuresList
                }),
            JsxRuntime.jsxs("div", {
                  children: [
                    res$1.totalPages > 1 ? JsxRuntime.jsx(Pagination.make, {
                            currentPage: res$1.currentPage,
                            totalPages: res$1.totalPages,
                            onPageClick: (function (pageNum) {
                                dispatch({
                                      TAG: "UpdatePage",
                                      _0: pageNum
                                    });
                              })
                          }) : null,
                    JsxRuntime.jsx("div", {
                          children: tmp$2,
                          className: css.paginationInfo
                        })
                  ],
                  className: css.paginationBar
                })
          ]
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(LocationBrochuresIndexHeader.make, {
                      title: "Data Center Brochures",
                      locationBrochuresCount: state.currentTotalLocationBrochures,
                      updateSearch: (function (search) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateSearchInput",
                                  _0: search
                                });
                          };
                        }),
                      updateProviderIds: (function (ids) {
                          return function (param) {
                            dispatch({
                                  TAG: "UpdateProviderIds",
                                  _0: ids
                                });
                          };
                        }),
                      reset: (function () {
                          dispatch("Reset");
                        })
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(LocationBrochuresIndexSidebar.make, {
                                    popularBrochures: index.popularBrochures,
                                    recentBrochures: index.recentBrochures
                                  }),
                              className: css.sidebarContainer
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsxs(Popover.make, {
                                            children: [
                                              JsxRuntime.jsxs(Popover.Trigger.make, {
                                                    className: css.popoverTrigger,
                                                    children: [
                                                      JsxRuntime.jsx(Control.AsLink.make, {
                                                            color: "Gray",
                                                            className: css.popoverLabel,
                                                            onClick: (function (param) {
                                                                
                                                              }),
                                                            children: tmp
                                                          }),
                                                      JsxRuntime.jsx(IconSelect.make, {
                                                            size: "XS",
                                                            color: "GrayText",
                                                            className: css.iconSelect
                                                          })
                                                    ]
                                                  }),
                                              JsxRuntime.jsxs(Popover.Body.make, {
                                                    position: {
                                                      TAG: "Below",
                                                      _0: "LeftEdge"
                                                    },
                                                    className: css.popoverBody,
                                                    children: [
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Title, A-Z",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Title",
                                                                              VAL: "Asc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Title, Z-A",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Title",
                                                                              VAL: "Desc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Location, A-Z",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Location",
                                                                              VAL: "Asc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Location, Z-A",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Location",
                                                                              VAL: "Desc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Provider, A-Z",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Provider",
                                                                              VAL: "Asc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          }),
                                                      JsxRuntime.jsx("div", {
                                                            children: JsxRuntime.jsx("div", {
                                                                  children: "Provider, Z-A",
                                                                  className: css.sortSelection,
                                                                  onClick: (function (param) {
                                                                      dispatch({
                                                                            TAG: "SortBy",
                                                                            _0: {
                                                                              NAME: "Provider",
                                                                              VAL: "Desc"
                                                                            }
                                                                          });
                                                                    })
                                                                }),
                                                            className: css.popoverRow
                                                          })
                                                    ]
                                                  })
                                            ]
                                          }),
                                      className: css.locationsSort
                                    }),
                                tmp$1
                              ],
                              className: css.locationBrochuresContainer
                            })
                      ],
                      className: css.contentContainer
                    })
              ],
              className: css.container
            });
}

var $$default = LocationBrochuresIndex$default;

export {
  css ,
  $$default as default,
}
/* css Not a pure module */
