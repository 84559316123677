// extracted by mini-css-extract-plugin
export var column = "LocationBrochuresIndexBrochure__column__w9rKS";
export var downloadContainer = "LocationBrochuresIndexBrochure__downloadContainer__at_rh";
export var image = "LocationBrochuresIndexBrochure__image__OtI0q";
export var imageContainer = "LocationBrochuresIndexBrochure__imageContainer__jl6ND";
export var linkContainer = "LocationBrochuresIndexBrochure__linkContainer__Qv5V5";
export var locationBrochure = "LocationBrochuresIndexBrochure__locationBrochure__R3aE5";
export var provider = "LocationBrochuresIndexBrochure__provider__qVmOS";
export var row = "LocationBrochuresIndexBrochure__row__zV8Xb";
export var subtitle = "LocationBrochuresIndexBrochure__subtitle__p9V1c";
export var title = "LocationBrochuresIndexBrochure__title__TyP8x";
export var titleContainer = "LocationBrochuresIndexBrochure__titleContainer__YmuYE";